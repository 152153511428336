<template>
<div id="layout">
  <v-app class="custom-height" v-if="$router.currentRoute.path !== '/popoutChart'">
    <v-navigation-drawer id="navigation_drawer" mobile-breakpoint="1024" :class="$store.state.isMobileView ? 'overflow-y-hidden' : 'overflow-y-auto'" class="customize" v-model="drawer" app width="64">
      <div width="64">
        <div @click="navigatePage('/home')" class="cursor d-flex justify-center align-center mx-2" style="height: 56px; border-bottom: solid 1px #D6D6D6">
          <img width="60" :src="$store.state.homePageLogo" id="side_head_logo"/>
        </div>

        <v-tabs class="sidebar mt-3" vertical v-model="selectedItem" :color="$store.state.buttonThemeColor" id="side_tabs">
          <v-tab class="sidebarTab" v-for="(item, i) in menuList" @click="changeRoute(item)" :key="i" :id="`${i}_side_tab`">
            <div class="menu">
               <customIcon :id="`${i}_side_tab_icon`" :name="item.svg.toLowerCase()" :width="'24'" :height="'24'" :color="$store.state.iconBlackColourCode"/>
              <div :id="`${i}_side_tab_text`" class="fsize11 primaryColor text-overflow">{{ item.text == 'marketwatch' ? 'MW List' : item.text }}</div>
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </v-navigation-drawer>

    <v-app-bar height="56" app flat class="appheader border-bottom-dark pa-0">
      <v-app-bar-nav-icon v-if="$store.state.isMobileView" @click="drawer = !drawer" id="navigation_drawer_icon" class="ma-0"><customIcon  :name="'drawer'" :width="'24'" :height="'24'" :color="'#6F6F6F'"/> </v-app-bar-nav-icon>
      <div class="row ma-0  pa-0 " :class="$store.state.isMobileView && !$store.state.isLapView ? 'justify-end' : 'justify-space-between'" id="app_bar_head">
        <div :widthChangeByView="!$store.state.isMobileView && !$store.state.isLapView ? '400' : !$store.state.isMobileView && $store.state.isLapView ? '450' : '0'" id="app_bar_left_head">
          <div class="row ma-0  d-flex justify-space-around text-uppercase" :class="$store.state.isMobileView && !$store.state.isLapView ? '' : ''" v-if="!$store.state.isMobileView">
            <div class="text-center fsize12 px-3 width-20 primaryColor hovershow" id="nifty_fifty">
              <div class="w-100" id="nifty_fifty_name">{{niftyFiftyArray.name}}</div>
              <div id="nifty_fifty_value">
                <span id="nifty_fifty_lp" :class="parseFloat(niftyFiftyArray.change) >= 0 ? 'positiveColor' : 'negativeColor'">{{niftyFiftyArray.value}}</span>
                <span id="nifty_fifty_chg" :class="parseFloat(niftyFiftyArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" class="ml-1">{{niftyFiftyArray.change ? `(${niftyFiftyArray.change}%)` : ''}}</span>
              </div>
                  <label id="nifty_fifty_icon" class="hoverBtn indexChart" style="top: 15px;position: absolute; display: none" @click="callTrandingViewChart(niftyFiftyArray)">
                      <customIcon :name="'chart'" :width="'24'" :height="'24'" :color="$store.state.iconBlackColourCode" />
                      </label>
                  
                   
            </div>
            <div class="text-center fsize12 border-lft px-3 width-20 primaryColor hovershow" id="nifty_bank">
              <div class="w-100" id="nifty_bank_name">{{niftyBankArray.name}}</div>
              <div id="nifty_bank_value">
                <span id="nifty_bank_lp" :class="parseFloat(niftyBankArray.change) >= 0 ? 'positiveColor' : 'negativeColor'">{{niftyBankArray.value}}</span>
                <span id="nifty_bank_chg" :class="parseFloat(niftyBankArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" class="ml-1">{{niftyBankArray.change ? `(${niftyBankArray.change}%)` : ''}}</span>
              </div>
                 <label id="nifty_bank_icon" class="hoverBtn indexChart" style="top: 15px;position: absolute; display: none" @click="callTrandingViewChart(niftyBankArray)">
                      <customIcon :name="'chart'" :width="'24'" :height="'24'" :color="$store.state.iconBlackColourCode" />
                      </label>
             
            </div>
            <div class="text-center fsize12 border-lft px-3 width-20 primaryColor hovershow" id="sensex">
              <div class="w-100" id="sensex_name">{{senSexArray.name}}</div>
              <div id="sensex_value">
                <span id="sensex_lp" :class="parseFloat(senSexArray.change) >= 0 ? 'positiveColor' : 'negativeColor'">{{senSexArray.value}}</span>
                <span id="sensex_chg" :class="parseFloat(senSexArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" class="ml-1">{{senSexArray.change ? `(${senSexArray.change}%)`: ''}}</span>
              </div>
                   <label id="sensex_icon" class="hoverBtn indexChart" style="top: 15px;position: absolute; display: none" @click="callTrandingViewChart(senSexArray)">
                      <customIcon :name="'chart'" :width="'24'" :height="'24'" :color="$store.state.iconBlackColourCode" />
                      </label>
            
            </div>
            <div class="text-center fsize12 border-lft px-3 width-20 primaryColor hovershow" id="indiavix">
              <div class="w-100" id="indiavix_name">{{indiaVixArray.name}}</div>
              <div id="indiavix_value">
                <span id="indiavix_lp" :class="parseFloat(indiaVixArray.change) >= 0 ? 'positiveColor' : 'negativeColor'">{{indiaVixArray.value}}</span>
                <span id="indiavix_chg" :class="parseFloat(indiaVixArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" class="ml-1">{{indiaVixArray.change ? `(${indiaVixArray.change}%)` : ''}}</span>
              </div>

     <label id="indiavix_chg" class="hoverBtn indexChart" style="top: 15px;position: absolute; display: none" @click="callTrandingViewChart(indiaVixArray)">
                      <customIcon :name="'chart'" :width="'24'" :height="'24'" :color="$store.state.iconBlackColourCode" />
                      </label>
                 
            </div>
            <div class="text-center fsize12 border-lft px-3 width-20 primaryColor hovershow" id="nifty_fin_service">
              <div class="w-100" id="nifty_fin_service_name">{{niftyFinServiceArray.name}}</div>
              <div id="nifty_fin_service_value">
                <span id="nifty_fin_service_lp" :class="parseFloat(niftyFinServiceArray.change) >= 0 ? 'positiveColor' : 'negativeColor'">{{niftyFinServiceArray.value}}</span>
                <span id="nifty_fin_service_chg" :class="parseFloat(niftyFinServiceArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" class="ml-1">{{niftyFinServiceArray.change ? `(${niftyFinServiceArray.change}%)` : ''}}</span>
              </div>
                  <label class="hoverBtn indexChart" style="top: 15px;position: absolute; display: none" @click="callTrandingViewChart(niftyFinServiceArray)">
                      <customIcon :name="'chart'" :width="'24'" :height="'24'" :color="$store.state.iconBlackColourCode" />
                      </label>
                    
            </div>
          </div>
        </div>
        <div class="pr-6 d-flex align-center" id="app_bar_right_head">
          <div class="d-flex flex-column justify-end text-end">
            <v-menu max-width="190" right bottom :offset-y="true" transition="slide-y-transition" :position-y="300" rounded>
              <template v-slot:activator="{ on, attrs }">
                <div text v-bind="attrs" v-on="on" class="fsize12">
                  <div class="primaryColor" id="acc_Name">{{ getUserSessionDto.accountName}}</div>
                  <div class="secondaryColor" id="acc_Id">{{ getUserId}}</div>
                </div>
              </template>
              <v-list class="py-0">
                <v-list-item class="body-2 header_menu" @click="navigatePage('/settings')">
                  <v-list-item-icon id="right_drop_down_setting_icon">
                    <customIcon :name="'settings'" :width="'20'" :height="'20'" :color="$store.state.iconGrayColor" />
                  </v-list-item-icon>
                  <v-list-item-content id="right_drop_down_setting_label">
                    <span class="ml-2">Settings</span>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item>
                  <v-switch dense v-model="$vuetify.theme.dark" inset class="themeSwitch" :ripple="false"></v-switch>
                  <v-list-item-content>
                    <span class="ml-6">Theme</span>
                  </v-list-item-content>
                </v-list-item> -->
                <!-- border-top-dark -->
                <v-list-item class="body-2 header_menu " @click="logoutDialog = true">
                  <v-list-item-icon id="right_drop_down_logout_icon">
                    <customIcon :name="'logout'" :width="'20'" :height="'20'" :color="$store.state.amogaColor" />
                  </v-list-item-icon>
                  <v-list-item-content id="right_drop_down_logout_label">
                    <a class="ml-2">Logout</a>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

        </div>
      </div>
    </v-app-bar>

    <v-main class="">
      <div  :widthChangeByView="!$store.state.isMobileView && !$store.state.isLapView ? '400' : !$store.state.isMobileView && $store.state.isLapView ? '450' : '0'" class="wrapper-router overflow-y-auto content-height">
        <router-view></router-view>
        
        <bod_popup v-if="$store.state.isValidTime" />
      </div>
      <mkWatch class="basket-wrapper" v-if="!$store.state.isMobileView" />
    </v-main>
  </v-app>
<div v-else class="h-100" >
    <router-view></router-view>
  </div>
  <div :class="showOrderWindow ? 'd-block' : 'd-none'">
    <orderWindow />
  </div>
  
    <authDialog />
    <brokerageDialog/>
    <risk_disclosure />
    <v-dialog v-model="logoutDialog" width="400px">
      <v-card>
        <div class="pb-2 px-4 pt-4">
          <span class="fsize20 orange--text mr-2">&#9888;</span> Are you sure want to logout?
          <button type="button" id="logout_close_icon" @click="logoutDialog = false" class="fsize21 float-right" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="row ma-0 pa-4 secondColor">
             <span class="fsize13"> Please be advised, all sessions, including API and algo based applications/sessions, will be logged out.</span>
        </div>
        <div class="px-4 pt-2 pb-4 d-flex align-end justify-end">
          <v-btn id="logout_confirm_btn" depressed :color="$store.state.buttonThemeColor" :loading="logoutLoader" :disabled="logoutLoader" @click="$store.dispatch('header/logout');$store.commit('orderWindow/setOrderWindow',false);" class="flat text-capitalize fsize14 white--text ">Confirm</v-btn>
          <v-btn id="logout_close_btn" depressed color="#fff" outlined @click="logoutDialog = false" class="flat  text-capitalize fsize14 black--text ml-3">Cancel</v-btn>

        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mkWatch from "../views/marketWatch.vue";
import orderWindow from "../components/orderWindow.vue";
import { mapGetters, mapState } from "vuex";
import customIcon from '../components/customIcon.vue'
import authDialog from '../components/authorizedialog.vue'
import brokerageDialog from  '../components/brokerageDialog.vue'
import risk_disclosure from "../components/risk_disclosure.vue";
import bod_popup from "./bod_popup.vue";
export default {
  data: () => ({
    drawer: null,
    showMenu: false,
    logoutDialog: false,
  }),
  computed: {
    ...mapGetters("authentication", [
      "getUserId",
      "getUserSession",
      "getUserSessionDto",
    ]),
    ...mapGetters("header", ['getIndex']),

    ...mapState("header", [
      "niftyFiftyArray",
      "niftyFinServiceArray",
      "niftyBankArray",
      "indiaVixArray",
      "senSexArray",
      "logoutLoader",
      "menuList",
      "tabSelection"
    ]),

    ...mapState("orderWindow", ["showOrderWindow", "windowLoading"]),
    selectedItem:{
        get() { return this.tabSelection},
        set(newValue) {
            this.$store.commit('header/setTabSelection' , newValue)
        }
    },
    isMobileView () {
      return this.$store.state.isMobileView
    }
  },
   beforeCreate(){
    window.tickersData = new Map()
   this.$store.dispatch("wsConnection/inValidateSession")

   if(localStorage.getItem('isEdis') && localStorage.getItem('isEdis') != "undefined"){
    var checkEdis = JSON.parse(localStorage.getItem('isEdis'))
    checkEdis ? this.$store.commit('holdings/setIsEdisHave' , checkEdis) : ''
    var accStatus = JSON.parse(localStorage.getItem('accStatus'))
    accStatus ? this.$store.commit('settings/setIsAccStatus' , accStatus) : ''
   }else{
   this.$router.currentRoute.path != '/home' && this.$router.currentRoute.path != '/funds' ? this.$store.dispatch("funds/getFundsLimits") : ''
   }
  },
  async created() {
    await this.$store.dispatch("header/getIndex", "NSE");
    await this.$store.dispatch("header/getIndex", "BSE");
    localStorage.removeItem('isMpin')
    localStorage.removeItem('whichStep')
    localStorage.removeItem('secQuestion')
  },
  components: {
    mkWatch,
    orderWindow,
    customIcon,
    authDialog,
    brokerageDialog,
    risk_disclosure,
    bod_popup
  },
  methods: {
    callTrandingViewChart(item) {
      let temp ={
                    data: item,
                    where:  'header'
                }
      this.$store.commit('setChartData' , temp);
     if(this.$router.currentRoute.path == "/chart"){
       this.$root.$refs.TVChartContainer.refresh(temp)
     }else{
       this.$router.push('/chart').catch(() => { })
       localStorage.setItem('chartData' , JSON.stringify(temp))
     } 
    },
    changeRoute(val) {
      this.$router.push("/" + val.text).catch(() => {});
    },
    navigatePage(route) {
     this.selectedItem = route == '/home' ? 0 : this.menuList.length - 1
      this.$router.push(route).catch(() => {});
    },
  },
  mounted() {
    this.selectedItem = this.menuList.findIndex(
          (std) => std.text === this.$router.currentRoute.path.replace("/", "")
        ); 
    
  },


};
</script>

<style scoped>
.wrapper-router {
  float: left;
  width: 100%;
  min-height: 1px;
  transition: all 0.1s;
}
.sidebar {
  width: 64px !important;
}
.v-navigation-drawer__content {
  overflow: clip !important;
}
.border-lft {
  border-left: 1px solid #ededed;
}
::v-deep.v-menu__content {
  top: 60px !important;
  right: 18px !important;
  left: auto !important;
}
/* .v-icon.v-icon{
  font-size: 20px !important;
} */
::v-deep.v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0px !important;
}

.hovershow:hover .indexChart{
  display: block !important;
}

.sidebar .v-tab {
  min-width: 64px !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  color: #282828 !important;
  padding: 8px 0px !important;
  letter-spacing: normal !important;
}
.sidebar .v-tab--active {
  color: #0065be !important;
}
.sidebar .v-tab--active {
filter: invert(13%) sepia(96%) saturate(3858%) hue-rotate(349deg) brightness(96%) contrast(86%)  !important;
}
.sidebar .v-tab:hover {
  filter: invert(13%) sepia(96%) saturate(3858%) hue-rotate(349deg) brightness(96%) contrast(86%)  !important;
}
.theme--light .darkThemeImg {
  display: contents !important;
}
.theme--dark .sidebar .v-tab .unactive_img {
  display: contents !important;
}
.theme--dark .sidebar .v-tab--active .unactive_img {
  display: inline-block !important;
}
.theme--dark .sidebar .v-tab {
  height: 56px !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  color: #ffffff !important;
  padding: 8px 0px !important;
  letter-spacing: normal !important;
}
.theme--light .sidebar .v-tab {
  height: 56px !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  color: #282828 !important;
  padding: 8px 0px !important;
  letter-spacing: normal !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
  background: #363636 !important;
}
.theme--dark.v-navigation-drawer {
  background-color: #1e1e1e !important;
}
.theme--dark.v-app-bar.v-toolbar {
  background-color: #1e1e1e !important;
}
[widthChangeByView="400"] {
  width: calc(100% - 400px) !important;
}
[widthChangeByView="450"] {
  width: calc(100% - 450px) !important;
}
[widthChangeByView="0"] {
  width: calc(100% - 0px) !important;
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 24px !important;
  margin-top: 13px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}
.text-overflow{
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width:60px
}
</style>