<template>
  <div>
    <form id="orderWindowForm" class="d-flex flex-column" name="orderwindow" @submit.prevent="placeOrder()"
      @keyup.esc="hideOrderWindow()"
      :class="{ slideUpCss: showOrderWindow && removeCss, slideDownCss: !showOrderWindow && !removeCss }">
      <v-flex xs12 sm12 md4 lg6 xl6 class="orderWindow">
        <draggable>

          <template slot="header">
            <v-sheet class="headerclass" :class="orderType == 'buy' ? 'buyColor' : 'sellColor'"
              :style="$store.state.windowWidth < 330 ? 'height:auto !important' : ''" id="orderWindow_sheet">
              <v-row class="ma-0">
                <v-col class="pa-0 col-7">
                  <div class="white--text fsize14 pb-1 text-capitalize"
                    v-if="priceRangeDetails[selectedItem] && priceRangeDetails[selectedItem].formatted_ins_name" id="ow_scrip_name">
                    {{
                      priceRangeDetails[selectedItem].formatted_ins_name
                    }} <span class="ml-2" v-if="nseBseType" id="ow_scrip_lp">{{ getCurrentLtp }}</span>
                    <!-- <v-progress-circular class="ml-2 flex-end" indeterminate v-if="windowLoading" size="18" :width="2" color="white"></v-progress-circular> -->
                  </div>

                  <!-- nse bse Radio switch -->
                  <v-radio-group :disabled="modifyOrder" v-if="nseBseType" v-model="nseBseRadioSwitch" row
                    class="orderWindowRadio pa-0 my-0 ml-0" height="24" id="ow_radio_group">
                    <span @click="nseBseRadioSwitch = 'NSE'; nseBseSwitch('NSE')" id="ow_radio_nse"
                      class="fsize12 white--text d-flex align-center mr-2 cursor">
                      <customIcon style="height: 16px" class="mr-2"
                        :name="nseBseRadioSwitch == 'NSE' ? 'radio-check' : 'radio-blank'" :width="'16'" :height="'16'"
                        :color="'#ffffff'" />
                      NSE: {{ nseSwitchPrice }}
                    </span>
                    <span @click="nseBseRadioSwitch = 'BSE'; nseBseSwitch('BSE')" id="ow_radio_bse"
                      class="fsize12 white--text d-flex align-center cursor">
                      <customIcon style="height: 16px" class="mr-2"
                        :name="nseBseRadioSwitch == 'BSE' ? 'radio-check' : 'radio-blank'" :width="'16'" :height="'16'"
                        :color="'#ffffff'" />BSE: {{ bseSwitchPrice }}
                    </span>
                  </v-radio-group>

                  <!-- Check NaN, null, empty, undefined for #### priceRangeDetails.Ltp && getCurrentLtp-->
                  <div v-else class="white--text fsize14 d-flex" id="ow_scrip_lp">
                    {{ getCurrentLtp }}
                  </div>

                </v-col>
                <v-col class="pa-0">
                  <div class="d-flex justify-end align-start">
                    <div id="ow_scrip_nudge_div" class="showNudge cursor" v-if="nudgeMessage && nudgeMessage.length > 0">
                      <span @click="$store.commit('orderWindow/setIsNudge', !isNudge)" id="nudge_info">
                        <customIcon class="d-flex align-center" :name="'info'" :width="'20'" :height="'20'"
                          :color="'#ffffff'" />
                      </span>

                      <span>
                        <div v-if="isNudge" class="test">
                          <div class="d-flex justify-end align-center">
                            <div class="popup nudgeli hoverNudge fsize14">
                              <div class="d-flex align-center justify-end mb-2">
                                <v-icon id="nudge_icon" color="white" size="16" @click="
                                  $store.commit(
                                    'orderWindow/setIsNudge',
                                    !isNudge
                                  )
                                  ">mdi-close</v-icon>
                              </div>
                              <ul class="list-flow pa-0" id="nudge_dialog_ul_arrow">
                                <li v-for="(list, index) in nudgeMessage" :key="index" :class="list.severity.toString().toLowerCase() ==
                                    'low'
                                    ? 'severity-low'
                                    : list.severity.toString().toLowerCase() ==
                                      'medium'
                                      ? 'severity-medium'
                                      : 'severity-high'
                                  " :id="`${index}_nudge_dialog_li_arrow`">
                                  <span class="font-medium" :id="`${index}_nudge_msg`">{{ list.msg }}</span>
                                  <a :id="`${index}_nudge_msg_link`" v-if="list.prompt" :href="list.prompt"
                                    class="text-decoration-none font-bold" target="_blank">Read more.</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="ml-4 d-flex flex-column align-end">
                      <v-switch v-model="buySellRadio" :disabled="modifyOrder" id="ow_switch" dense
                        @click="toggleOrderWindow()" hide-details inset class="orderSwitch ma-0" color="white"></v-switch>
                      <!-- Check for both CDS and BCD -->
                      <div class="white--text fsize12 pt-1" v-if="lowerRange != 0 && upperRange != 0"
                        id="ow_scrip_uc_and_lc_val">
                        {{
                          `DPR: ${currentExchange == "CDS" || currentExchange == "BCD"
                              ? parseFloat(lowerRange).toFixed(4) : parseFloat(lowerRange).toFixed(2)
                            } -
                                                ${currentExchange == "CDS" || currentExchange == "BCD"
                              ? parseFloat(upperRange).toFixed(4) : parseFloat(upperRange).toFixed(2)
                            }`
                        }}
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
          </template>

          <template slot="main">
            <v-sheet class="border-bottom-light orderwindow">
              <!-- order type tabs -->
              <v-tabs :show-arrows="true" @change="changeOrderTab()" id="ow_scrip_tabs" v-model="orderTab"
                :color="orderType == 'buy' ? '#2992ec' : '#d13535'" dense max-width="30"
                class="orderWindowTab border-bottom-light">
                <v-tab class="primaryColor" :transition="false" :disabled="modifyOrder" :id="`ow_scrip_${item}_tab`"
                  @click="selectedIndex(item)" v-for="item in orderTypeList" :key="item">
                  <v-tooltip max-width="200" top color="toolTipColor toolTipTop">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ item }}</span> </template><span class="fsize12">{{
                        item == "Regular"
                        ? "Regular order"
                        : item == "Cover"
                          ? "Cover order"
                          : item == "AMO"
                            ? "After Market Order (for the next day)"
                            : "Bracket order"
                      }}</span>
                  </v-tooltip>
                </v-tab>
              </v-tabs>

              <v-tabs-items :transition="false" v-model="orderTab" class="px-2 py-2 border-bottom">
                <v-row class="ma-0 px-2 pb-3">
                  <!-- priceBtns -->
                  <v-col class="pa-0 mt-2">
                    <v-slide-group transition="none" v-model="prodType" mandatory class="pa-0 orderSlideGroup"
                      hide-arrows>
                      <v-slide-item v-for="(item, i) in showproductItems" :key="i" v-bind:value="item"
                        v-slot="{ active, toggle }">
                        <v-tooltip max-width="200" top color="toolTipColor toolTipTop">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @change="setProductTab()" depressed v-bind="attrs" v-on="on"
                              :class="modifyOrder ? 'disabledSlideBtn' : ''" :color="active
                                  ? orderType == 'buy'
                                    ? 'buyColor'
                                    : 'sellColor'
                                  : 'unActiveColor'
                                " height="26" min-width="54" class="fsize11 px-1 mr-2 rounded-sm orderProdBtn"
                              @click="toggle" :id="`${item}_btn`">
                              {{ item }}
                            </v-btn>
                          </template>
                          <span class="fsize12">{{
                            item == "MIS"
                            ? "Margin Intraday Squareoff: Requires lower margin. Has to be exited before market close."
                            : item == "CNC"
                              ? "CashNCarry: Longterm investment.Requires full upfront margin"
                              : item == "NRML"
                                ? "Normal: Carry forward positions overnight."
                                : ""
                          }}</span>
                        </v-tooltip>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                  <!-- priceBtns -->
                  <v-col class="pa-0 mt-2 d-flex justify-end">
                    <v-slide-group transition="none" v-model="priceType" mandatory class="pa-0 orderSlideGroup"
                      hide-arrows>
                      <v-slide-item v-for="(item, i) in showPriceItems" :key="i" v-bind:value="item"
                        v-slot="{ active, toggle }">
                        <v-tooltip max-width="200" top color="toolTipColor toolTipTop">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="toggle" v-bind="attrs" v-on="on" depressed :color="active
                                ? orderType == 'buy'
                                  ? 'buyColor'
                                  : 'sellColor'
                                : 'unActiveColor'
                              " height="26" min-width="54" class="ml-2 px-1 fsize11 rounded-sm text-capitalize"
                              :id="`${item}_btn`">
                              {{
                                item == "L"
                                ? "Limit"
                                : item == "MKT"
                                  ? "Market"
                                  : item
                              }}
                            </v-btn>
                          </template>
                          <span class="fsize12">{{
                            item == "L"
                            ? `${capitalizeFirstLetter(
                              orderType
                            )} at preferred price`
                            : item == "MKT"
                              ? `${capitalizeFirstLetter(
                                orderType
                              )} at Market price`
                              : item == "SL"
                                ? `${capitalizeFirstLetter(
                                  orderType
                                )} at preferred price with a stoploss`
                                : `${capitalizeFirstLetter(
                                  orderType
                                )} at market price with stoploss`
                          }}</span>
                        </v-tooltip>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>

                <!-- input fields common -->
                <v-row class="ma-0">
                  <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                    <label class="line-height20 fsize12 primaryColor" id="ow_qty_label">Quantity <span class="ml-1">(Lot: {{ getViewLotSize() }})</span></label>
                    <div>
                      <v-text-field id="qty" ref="qty" @input="quantityValidation" class="orderInput min-w-150" :min="0"
                        v-model.number="quantity" :step="minlot" @keypress="checkQuantity($event)" height="40" dense
                        type="number" autofocus single-line outlined hide-details autocomplete="off"></v-text-field>
                    </div>
                    <div class="error-msg fsize10">
                      {{ quantityValidation() }}
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                    <label class="line-height20 fsize12 primaryColor" id="ow_prc_label">Price<span class="ml-1">(Tick: {{ getViewTickSize() }})</span></label>
                    <div>
                      <v-text-field id="price" class="orderInput min-w-150" @input="priceValidation" :disabled="!isPrice"
                        :min="0" v-model.number="price" @keypress="decimalAllowed($event)" :step="tickPrice" height="40"
                        dense type="number" single-line outlined hide-details>
                      </v-text-field>
                    </div>
                    <div class="error-msg fsize10" v-if="isPrice">
                      {{ priceValidation() }}
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                    <label class="line-height20 fsize12 primaryColor" id="ow_trigger_prc_label">{{ orderTypeList[orderTab]
                      == 'Cover' ? 'SL Trigger Price' : 'Trigger Price' }}</label>
                    <div>
                      <v-text-field id="triggerPrice" class="orderInput min-w-150" @input="triggerPriceValiation"
                        :disabled="!isTrgPrice" :min="0" v-model.number="triggerPrice" :step="tickPrice"
                        @keypress="decimalAllowed($event)" height="40" dense type="number" single-line outlined
                        hide-details></v-text-field>
                    </div>
                    <div class="error-msg fsize10" v-if="isTrgPrice">
                      {{ triggerPriceValiation() }}
                    </div>
                  </v-col>
                </v-row>

                <!-- input fields for bracket order -->
                <v-row class="mx-0 mb-0 mt-2" v-if="this.orderTypeList[this.orderTab] == 'Bracket'">
                  <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                    <label class="line-height20 fsize12 primaryColor" id="ow_target_label">Target</label>
                    <div>
                      <v-text-field id="targetPrice" class="orderInput min-w-150" @input="targetPriceValidation" :min="0"
                        v-model="targetPrice" :step="tickPrice" height="40" @keypress="decimalAllowed($event)" dense
                        type="number" single-line outlined hide-details></v-text-field>
                    </div>
                    <div class="error-msg fsize10">
                      {{ targetPriceValidation() }}
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                    <label class="line-height20 fsize12 primaryColor" id="ow_SL_label">Stoploss</label>
                    <div>
                      <v-text-field id="stopLossPrice" class="orderInput min-w-150" @input="stopLossPriceValidation"
                        :min="0" v-model="stopLossPrice" :step="tickPrice" height="40" @keypress="decimalAllowed($event)"
                        dense type="number" single-line outlined hide-details></v-text-field>
                    </div>
                    <div class="error-msg fsize10">
                      {{ stopLossPriceValidation() }}
                    </div>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                  <label class="line-height20 fsize12 primaryColor">Trailing Stoploss</label>
                  <div>
                    <v-text-field id="trailStopLossPrice" class="orderInput min-w-150" @input="trailPriceValidation" :min="0" v-model="trailStopLossPrice" :step="tickPrice" height="40"  @keypress="decimalAllowed($event)" dense type="number" single-line outlined hide-details></v-text-field>
                  </div>
                </v-col> -->
                </v-row>
                <v-row class="ma-0 px-3 line-height20 align-center justify-space-between">
                <div v-if="quantity &&
                Number(quantity) > 0 &&
                Number(freezeQty) > 0 &&
                Number(freezeQty) < Number(quantity) && !quantityValidation()"
              id="freeze_err" class="error-msg fsize10"
            >
              <span
                class="negative fsize12 font-semibold"
                id="freeze_err_label1"
                >Exceeds Freeze Quantity.
                <span class="font-normal" id="freeze_err_label2">
                  Will be implemented as {{ Math.ceil(parseInt(quantity) / parseInt(freezeQty))  }} Orders. Please check Orderbook for status.</span
                >
              </span>
            </div>
          </v-row>

                <v-row class="ma-0 pt-2 px-3 line-height20 align-center justify-space-between">
                  <v-tooltip max-width="200" top color="toolTipColor toolTipTop">
                    <template v-slot:activator="{ on, attrs }">
                      <label v-bind="attrs" v-on="on" class="More options cursor fsize12 user-select-none"
                        id="ow_more_btn" @click="showMore = !showMore;">More
                        <span class="chevron bottom"></span></label></template><span class="fsize12">More Options</span>
                  </v-tooltip>
                  <div class="d-flex align-center fsize12 cursor linkColor">
                    <div
                      @click="isBrokerageCalled ? $store.commit('orderWindow/setIsBrokerageCalled', false) : $store.dispatch('orderWindow/getBrokerageDta', 'view');"
                      id="ow_view_charges_label"> View Charges <span class="chevron bottom"></span> </div>

                    <div style="width: 20px">
                      <v-progress-circular class="ml-2" indeterminate size="18" v-if="brokerageLoader" :width="2"
                        color="blue"></v-progress-circular>
                    </div>
                  </div>
                </v-row>

                <!-- disclosed Qty field -->
                <v-row class="ma-0 d-flex justify-end align-center" v-if="showMore">
                  <v-col class="pa-0"></v-col>
                  <v-col class="py-0 pl-0 pr-7 d-flex flex-column">
                    <label class="fsize12 line-height20" id="ow_validity_label">Validity</label>
                    <v-slide-group v-model="validityType" mandatory class="pa-0" width="178" hide-arrows>
                      <v-slide-item v-for="(item, i) in showValidityItems" :key="i" v-bind:value="item"
                        v-slot="{ active, toggle }">
                        <v-tooltip max-width="200" top color="toolTipColor toolTipTop">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn depressed v-bind="attrs" v-on="on" :color="active
                                ? orderType == 'buy'
                                  ? 'buyColor'
                                  : 'sellColor'
                                : 'unActiveColor'
                              " height="26" min-width="54" class="fsize11 px-1 rounded-sm mr-2" :value="item"
                              @click="toggle" :id="`${item}_btn`">
                              {{ item }}</v-btn>
                          </template><span class="fsize12">{{
                            item == "DAY"
                            ? "Regular day order"
                            : "Immediate or Cancel"
                          }}</span>
                        </v-tooltip>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>

                  <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                    <label class="line-height20 fsize12 primaryColor" id="ow_disclosed_qty_label">Disclosed Qty.</label>
                    <div>
                      <v-text-field id="discQty" class="orderInput min-w-150" @input="discQtyValidation" :min="0"
                        v-model.number="disclosedQuantity" :disabled="!isDisclosedQty" height="40" dense type="number"
                        single-line hide-details outlined></v-text-field>
                    </div>
                    <div class="error-msg fsize10">
                      {{ discQtyValidation() }}
                    </div>
                  </v-col>
                </v-row>
                <v-row class="ma-0 d-flex justify-end align-center" v-if="isBrokerageCalled && !brokerageLoader">
                  <div class="row px-2  py-0 mx-0 my-0 mt-2 w-100  d-flex justify-space-between">
                    <div class="w-100 mb-3 fsize14 font-weight-bold primaryColor l-height-24 d-flex align-center">
                      <div id="Transaction_Estimates_label">
                        Transaction Estimates :
                      </div>
                      <div class="d-flex align-center ml-6 font-weight-normal">
                        <v-radio-group class="brokerageRadio ma-0" id="ow_brokerage_radio_group"
                          v-model="brokerageData.radio" row hide-details="">
                          <v-radio id="ow_brokerage_interday_radio" label="Intraday" value="Intraday"></v-radio>
                          <v-radio id="ow_brokerage_delivery_radio" si label="Delivery" value="Delivery"></v-radio>
                        </v-radio-group>
                      </div>

                      <div>
                        <span v-if="!refreshBrokerageLoader">
                          <v-tooltip bottom color="toolTipColor toolTipBottom">

                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on"
                                @click="$store.dispatch('orderWindow/getBrokerageDta', 'refresh')" id="ow_refresh_icon">
                                <customIcon :name="'refresh'" class="mx-1 cursor d-flex" :width="'16'" :height="'16'"
                                  :color="$store.state.amogaColor" />
                              </span>
                            </template>
                            <span>Refresh</span>
                          </v-tooltip>
                        </span>

                        <v-progress-circular class="ml-2" indeterminate size="18" v-else :width="2"
                          color="blue"></v-progress-circular>
                      </div>
                    </div>

                    <div class="d-flex align-center justify-space-between w-100 fsize12  primaryColor l-height-24"
                      v-if="brokerageData">
                      <div class="text-uppercase" id="brokerage_label">{{ `${brokerageData.radio == "Delivery" ? 'Delivery Brokerage' : 'Intraday Brokerage'}`}}</div>
                      <div id="brokerage_val">&#8377; {{ brokerageData.radio == "Delivery" ?
                        ruppesFormat(parseFloat(brokerageData.DeliveryBrokerage).toFixed(2)) :
                        ruppesFormat(parseFloat(brokerageData.IntradayBrokerage).toFixed(2)) }}</div>
                    </div>
                    <div class="d-flex align-center justify-space-between w-100 fsize12  primaryColor l-height-24"
                      v-if="brokerageData">
                      <div class="text-uppercase" id="brokerage_charges_label">{{ `${brokerageData.radio == "Delivery" ?
                        'Delivery Other Charges' : 'Intraday Other Charges'}` }}</div>
                      <div id="brokerage_charges_val">&#8377; {{ brokerageData.radio == "Delivery" ?
                        ruppesFormat(parseFloat(brokerageData.DeliveryOtherCharges).toFixed(2)) :
                        ruppesFormat(parseFloat(brokerageData.IntradayOtherCharges).toFixed(2)) }}</div>
                    </div>
                    <div class="row pt-2 px-0 ma-0 w-100 d-flex primaryColor fsize12" id="brokerage_info">
                      # Above mentioned charges include brokerage, taxes & regulatory charges. These are tentative figures
                      and actual charges may differ basis the order execution price. Kindly refer the contract note to
                      view actual charges levied.
                    </div>
                  </div>
                </v-row>
              </v-tabs-items>
            </v-sheet>
          </template>

          <!-- footer -->
          <template slot="footer">
            <v-sheet class="px-4 py-3 footerOrderWindow">
              <v-row class="ma-0">
                <v-col class="pa-0 d-flex align-center justify-start ">
                  <div class="fsize12 secondaryColor d-flex align-center">
                    <div class="flex-column d-flex">
                      <span id="ow_mar_req_label">
                        Margin Required <span class="font-weight-bold ml-1" id="ow_mar_req_val"> &#8377; {{
                          getAvailableMargin && getAvailableMargin['Order Margin'] ?
                          ruppesFormat(parseFloat(getAvailableMargin['Order Margin']).toFixed(2)) : 'NA' }} </span>
                      </span>
                      <span class="mt-1" id="ow_mar_ava_label">
                        Margin Available <span class="font-weight-bold ml-1" id="ow_mar_ava_val"> &#8377; {{
                          getAvailableMargin && getAvailableMargin['Available Cash'] ?
                          ruppesFormat(parseFloat(getAvailableMargin['Available Cash']).toFixed(2)) : 'NA' }} </span>
                      </span>
                    </div>
                    <span class="ml-2">
                      <span v-if="!marginLoader">
                        <v-tooltip top color="toolTipColor toolTipTop">

                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" @click="$store.dispatch('orderWindow/getMarginAvailable')"
                              id="ow_mar_refresh_icon">
                              <customIcon class="d-flex cursor" :name="'refresh'" :width="'16'" :height="'16'"
                                :color="'#ffffff'" />
                            </span>

                          </template>
                          <span>Refresh</span>
                        </v-tooltip>
                      </span>
                      <span v-else>
                        <v-progress-circular class="ml-2" indeterminate v-if="marginLoader" size="18" :width="2"
                          color="blue"></v-progress-circular>
                      </span>
                    </span>
                  </div>
                </v-col>
                <v-col class="pa-0 d-flex justify-end">
                  <v-btn :loading="loading" id="ow_place_btn" type="submit" value="placeOrder" :disabled="loading"
                    depressed height="40" width="100" class="text-capitalize rounded"
                    :class="orderType == 'buy' ? 'buyColor' : 'sellColor'">{{ modifyOrder ? "Modify" : orderType
                    }}</v-btn>
                  <v-btn depressed outlined height="40" width="100" color="#9b9b9b" id="ow_place_cancel_btn" class="
                    text-capitalize
                    secondaryColor
                    rounded-sm-md
                    cancelbtn
                    ml-2
                  " @click="hideOrderWindow">Cancel</v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </template>

        </draggable>
      </v-flex>
    </form>
    <v-dialog v-model="nudgeDialog" width="550px" style="z-index: 999999999">
      <v-card class="pa-4 nudgeli">
        <div id="nudge_dialog_ul">Security is under - {{ getNudgeMessage() }}, would you like to continue?</div>
        <v-row class="ma-0 d-flex justify-end pt-2">
          <v-btn @click="callService" id="nudge_proceed_btn" depressed height="40" width="100"
            class="text-capitalize rounded buyColor">Proceed</v-btn>
          <v-btn id="nudge_cancel_btn" depressed outlined height="40" width="100" color="#9b9b9b"
            class="text-capitalize secondaryColor rounded-sm-md cancelbtn ml-2"
            @click="nudgeDialog = !nudgeDialog">Cancel</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import orderWindowjs from "../mixins/orderWindow";
import customIcon from '../components/customIcon.vue'
import commonFunc from '../mixins/commonFunctions'
export default {
  mixins: [orderWindowjs, commonFunc],
  components: {
    customIcon
  }
};
</script>

<style>
.headerclass {
  cursor: move;
  padding: 12px 18px !important;
  background: #2992ec;
  height: 74px;
  /* width: 550px; */
  border-radius: 3px 3px 0 0 !important;
}

.orderWindowRadio .v-icon.v-icon {
  font-size: 16px !important;
  color: #ffffff !important;
}

.orderWindowRadio .v-input--selection-controls .v-input__slot>.v-label,
.v-input--selection-controls .v-radio>.v-label {
  font-size: 12px !important;
}

.orderWindowRadio .v-input--selection-controls__input {
  width: 16px !important;
  height: 16px !important;
}

.orderWindowRadio .v-input--selection-controls__input {
  margin-right: 4px !important;
}

.orderWindowRadio .v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 12px !important;
}

.orderWindowRadio .theme--light.v-label {
  color: #ffffff !important;
}




.brokerageRadio .v-icon.v-icon {
  font-size: 16px !important;
}

.brokerageRadio .v-input--selection-controls .v-input__slot>.v-label,
.v-input--selection-controls .v-radio>.v-label {
  font-size: 12px !important;
}

.brokerageRadio .v-input--selection-controls__input {
  width: 16px !important;
  height: 16px !important;
}

.brokerageRadio .v-input--selection-controls__input {
  margin-right: 4px !important;
}

.brokerageRadio .v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 12px !important;
}

.brokerageRadio .theme--light.v-label {
  color: #282828 !important;
}

.orderWindowTab .v-tabs-bar {
  height: 38px !important;
}

.orderWindowTab .v-tab {
  font-size: 12px !important;
  text-transform: inherit !important;
  font-weight: normal !important;
  letter-spacing: inherit !important;
}

.prodTypeBtn {
  padding: 5px 8px;
  border-radius: 2px;
  font-size: 11px;
  width: 60px;
  height: 26px;
  text-align: center;
  color: #282828 !important;
  background-color: #e6e6e6;
  cursor: pointer;
}

.line-height20 {
  line-height: 20px !important;
}

.line-height24 {
  line-height: 24px !important;
}

.buyColor {
  background-color: #2992ec !important;
}

.sellColor {
  background-color: #d13535 !important;
}

.unActiveColor {
  background-color: #e6e6e6 !important;
}

.v-btn.buyColor,
.v-btn.sellColor {
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.v-btn:not(.v-btn--outlined).unActiveColor {
  color: #282828 !important;
  text-transform: inherit !important;
}

.orderSlideGroup .v-slide-group__content>.v-btn.v-btn:nth-child(2) {
  margin-left: 8px !important;
}

.border-a8a8a8 {
  border: solid 1px #a8a8a8;
}

.orderField {
  width: 150px;
  height: 40px;
  outline: none !important;
  box-shadow: none !important;
}

.chevron::before {
  border-style: solid;
  border-width: 0.1em 0.1em 0 0 !important;
  content: "";
  display: inline-block;
  height: 8px;
  left: 2px;
  position: relative;
  top: 2px;
  transform: rotate(-225deg);
  vertical-align: top;
  width: 8px;
  margin-top: 4px;
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.orderWindow .v-btn {
  letter-spacing: 0 !important;
}

.v-input--selection-controls__ripple {
  height: 0px !important;
}

.v-input--switch.v-input--dense.v-input--switch--inset .v-input--switch__track {
  height: 16px !important;
  width: 38px !important;
  top: calc(50% - 12px);
  left: -3px;
}

.v-input--switch.v-input--dense .v-input--switch__thumb {
  width: 12px !important;
  height: 12px !important;
}

.v-input--is-disabled.orderInput {
  background: url(../assets/images/strip.svg) !important;
  color: #a8a8a8 !important;
}

.v-input--is-focused:focus {
  border: #000000 !important;
}

.footerOrderWindow {
  background: #f3f3f3 !important;
}

.theme--dark .footerOrderWindow {
  background: #1e1e1e !important;
}

.pointer-events-none {
  pointer-events: none;
  cursor: disabled;
}

.orderProdBtn .v-slide-item--active.v-btn--disabled {
  background: #a7d3f9 !important;
  opacity: 0.5 !important;
}

.disabledSlideBtn {
  opacity: 0.5 !important;
  pointer-events: none;
}

button:disabled {
  cursor: not-allowed;
}

.theme--dark.v-sheet.orderwindow {
  background: #686868 !important;
}

.orderWindowTab .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #282828 !important;
}

.slideDownCss {
  transition: transform 0.25s ease-out !important;
  animation-duration: 0.55s;
  -webkit-animation: fadeOutDown;
  animation: fadeOutDown;

  top: auto;
  bottom: 0px;
}

.slideUpCss {
  -webkit-animation: fadeInUp 0.25s ease;
  animation: fadeInUp 0.25s ease;
}

.orderSwitch .v-input--switch--inset .v-input--switch__track,
.v-input--switch--inset .v-input--selection-controls__input {
  width: 24px !important;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.orderWindowTab .v-tabs>.v-tabs-bar .v-tab--disabled .v-tab--active {
  color: unset !important;
}

.min-w-150 {
  min-width: 150px;
}

@media (max-width: 1023px) {
  #draggable-container {
    left: 10% !important;
    top: 0 !important;
    display: block !important;
    width: auto !important;
  }
}

@media (max-width: 629px) {
  #draggable-container {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0;
    width: 100%;
    overflow-y: auto;
  }
}

.showNudge {
  position: relative;
  display: inline-block;
}

.hoverNudge {
  display: block;
  position: relative;
  z-index: 9999999;
  background-color: #333;
  color: #ffffff;
  border-radius: 4px;
}

.popup {
  background-color: #333;
  padding: 10px 14px;
  width: 550px;
  color: #fff;
  border-radius: 4px;
  text-align: left;
  position: absolute;
  right: -65px;
  bottom: 31px;
}

ul.list-flat,
ul.list-flat li {
  list-style: none;
  margin: 0;
  padding: 0 !important;
}

.nudgeli li {
  list-style: none !important;
  padding-left: 10px !important;
  margin-bottom: 10px !important;
  border-left: 4px solid #9b9b9b;
}

.nudgeli li.severity-low {
  border-left-color: #ff8f00;
}

.nudgeli li.severity-medium {
  border-left-color: #df514c;
}

.nudgeli li.severity-high {
  border-left-color: #df514c;
}

.test::after {
  content: "";
  position: absolute;
  right: 3px;
  top: -11px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: #333;
  border-bottom: 0;
  margin-bottom: -10px;
}</style>