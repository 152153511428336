<template>
    <div class="alignPopCenter" :style="!$store.state.isMobileView && !$store.state.isLapView ? 'width:calc(100% - 400px)' : !$store.state.isMobileView && $store.state.isLapView ? 'width:calc(100% - 450px)' : 'width:100%'" v-if="bod_pop_msg[$route.path.toString().replace('/', '')] && bod_pop_msg[$route.path.toString().replace('/', '')].isEnable">
        <div class="popcss d-flex" :style="$store.state.isMobileView ? 'width: 100%' : 'width:450px'">
        <div>
            {{ bod_pop_msg[$route.path.toString().replace('/', '')].val}} 
        </div>
        <div class="fsize20 cursor pl-2" @click="updateVal">
            &times;
        </div>
    </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'bod_popup',
    data(){
        return {
        }
    },
    methods:{
        updateVal(){
            if(this.bod_pop_msg[this.$route.path.toString().replace('/', '')]){
              this.bod_pop_msg[this.$route.path.toString().replace('/', '')].isEnable = false
              localStorage.setItem('bod_msg', JSON.stringify(this.bod_pop_msg))
              this.$store.commit('set_bod_pop_msg', this.bod_pop_msg)
            }
        }
    },
    computed:{
        ...mapState(['bod_pop_msg'])
    },
    mounted(){
        let msg = localStorage.getItem('bod_msg') && localStorage.getItem('bod_msg') != "undefined" ? JSON.parse(localStorage.getItem('bod_msg')) : this.bod_pop_msg 
        this.$store.commit('set_bod_pop_msg', msg)
    }
}
</script>
<style>
.popcss{
    margin: 8px 16px;
    font-size: 14px;
    padding: 8px;
    
    color: #000000;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px 0px;
}
.theme--dark .popcss{
    margin: 8px 16px;
    font-size: 14px;
    padding: 8px;
    color: #ffffff;
    background-color: #000000;
    box-shadow: 0px 0px 2px 0px;
}
.alignPopCenter{
    position: fixed;
    bottom: 5px;
    z-index: 9;
    display: flex;
    justify-content: center;
}
</style>