<template>
  <v-dialog v-model="brokageDialog" width="700" transition="slide-y-transition">
    <v-card class="px-0 pt-0 pb-6">
        <div class="row px-0 py-0  ma-0 w-100">
            <v-row class="ma-0 px-6 pt-4" :class="orderType == 'buy' ? 'buyColor' : 'sellColor'">
              <v-col class="pa-0">
                <div class="white--text fsize14 pb-1 text-capitalize d-flex align-center justify-space-between">Tradelite - Transaction Estimator  <div class="white--text fsize14 pb-1 text-capitalize" v-if="brokageDialog && priceRangeDetails[selectedItem].formatted_ins_name">
                  {{
                     priceRangeDetails[selectedItem].formatted_ins_name
                  }} <span class="pl-4">{{
                    priceRangeDetails[selectedItem].exch
                  }}</span>
                </div>
                </div>

              </v-col>
              
            </v-row>
        </div>
        <div class="row px-6 pt-4 pb-0 ma-0 w-100">
            <div class="w-100 mb-3 fsize16 font-weight-bold primaryColor l-height-24">
                Transaction Overview
            </div>

            <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="quantity">
                <div>Quantity</div> <div>{{quantity}}</div>
            </div>
            <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="price">
                <div>{{orderType.toString().toUpperCase()}} Price ({{priceType == 'L' ? 'Limit' : priceType == 'MKT' ? 'MARKET' : priceType}})</div> <div>&#8377; {{ruppesFormat(parseFloat(price).toFixed(currentExchange == "CDS" || currentExchange == "BCD" ? 4 : 2))}}</div>
            </div>
           <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData">
                <div>Trade Value</div> <div>&#8377;  {{ruppesFormat(parseFloat(brokerageData.tradeVal).toFixed(currentExchange == "CDS" || currentExchange == "BCD" ? 4 : 2))}}</div>
            </div>
            <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData">
                <div>Order Type</div> <div>{{brokerageData.prodType}}</div>
            </div>
        </div>

        <div class="row px-6 pt-4 pb-0 ma-0 w-100 d-flex justify-space-between">
            <div class="w-100 mb-3 fsize16 font-weight-bold primaryColor l-height-24 d-flex align-center">
                <div>
                  Transaction Estimates
                </div>
                <div class="d-flex align-center ml-6 font-weight-normal">Interday<v-switch class="ml-2 mt-0" style="height:28px" v-model="brokerageData.toggle"
      
    ></v-switch>Delivery</div>
            </div>

            <div class="d-flex align-center justify-space-between w-100 fsize12  primaryColor l-height-24" v-if="brokerageData">
                <div class="text-uppercase">{{`${brokerageData.toggle ? 'Delivery Brokerage' : 'Intraday Brokerage'}`}}</div> <div>&#8377; {{brokerageData.toggle ? parseFloat(brokerageData.DeliveryBrokerage).toFixed(2) :  parseFloat(brokerageData.IntradayBrokerage).toFixed(2)}}</div>
            </div>
            <div class="d-flex align-center justify-space-between w-100 fsize12  primaryColor l-height-24" v-if="brokerageData">
                <div class="text-uppercase">{{`${brokerageData.toggle ? 'Delivery Other Charges' : 'Intraday Other Charges'}`}}</div> <div>&#8377; {{brokerageData.toggle ? parseFloat(brokerageData.DeliveryOtherCharges).toFixed(2) :  parseFloat(brokerageData.IntradayOtherCharges).toFixed(2)}}</div>
            </div>
           
        </div>

        <div class="row pt-4 px-6 ma-0 w-100 d-flex justify-space-between ">
          <div class="primaryColor fsize12 col-9 pa-0 ma-0">
            # Above mentioned charges include brokerage, taxes & regulatory charges. These are tentative figures and actual charges may differ basis the order execution price. Kindly refer the contract note to view actual charges levied.
          </div>
           <div class="col-3 pa-0 ma-0 d-flex justify-end">
             <v-btn
          depressed
          outlined
          height="40"
          width="100"
          color="#9b9b9b"
          class="text-capitalize secondaryColor rounded-sm-md cancelbtn ml-2"
          @click="brokageDialog = false"
          >Close</v-btn
        >
           </div>
        </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import commonFunc from '../mixins/commonFunctions'
export default {
  mixins:[commonFunc],
  data() {
    return {
    };
  },
  computed:{
    ...mapState('orderWindow', ["brokerageData", 'priceRangeDetails', 'orderType', 'selectedItem', 'priceType', 'quantity', 'price', 'currentExchange']),
    brokageDialog:{
        get(){
            return this.$store.state.orderWindow.brokageDialog
        },
        set(val){
            this.$store.commit('orderWindow/setBrokageDialog' , val)
        }
    }
  }
};
</script>

<style>
</style>