<template>
   <!-- authorization Dialog  -->
    <v-dialog v-model="authDialog" width="344px" transition="slide-y-transition">
      <v-card class="pa-6">
        <div class="Img-div text-center rounded">
          <img :src="$store.state.loginLogo" class="h-40" alt="Logo" Lazy="load" decode="async" />
        </div>
        <div class="
                  fsize20
                  primaryColor
                  font-weight-regular
                  mb-2
                  text-center
                ">
          Authorize {{ $store.state.projectName }}
        </div>
        <div class="text-center fsize14 text-center">
          Permission required by the app
        </div>
        <ul class="fsize14 ml-4 pt-4 mb-8 primaryColor">
          <li>Access holding and positions portfolio</li>
          <li>Place, modify, and cancel otders</li>
          <li>View your account balance and margins</li>
          <li>View your profile details</li>
        </ul>
        <v-btn depressed :color="$store.state.buttonThemeColor" @click="vaildateAuthorize" :loading="getAuthorizatonLoader" :disabled="getAuthorizatonLoader" class="flat w-100 text-capitalize fsize14 white--text">Authorize</v-btn>
        <v-btn depressed color="#f9f9f9" class=" w-100
                    text-capitalize
                    primaryColor
                    rounded-sm-md
                    cancelbtn
                    mt-2
                  " @click="$store.commit('authdialog/setAuthDialog', false);">Cancel</v-btn>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters , mapState} from 'vuex'
export default {
    data(){
        return {

        }
    },
    computed:{
        ...mapGetters("authentication", [
      "getUserId",
      "getAuthorizatonLoader",
    ]),
      ...mapState("authdialog", [
      "authPayload",
    ]),
         authDialog: {
      get() {
        return this.$store.state.authdialog.authDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("authdialog/setAuthDialog", value);
        }
        return value;
      },
    },
    },
    methods:{
        async vaildateAuthorize() {
      let user = {
        userId: this.getUserId,
        vendor: this.authPayload.vendor,
        callBackUrl: this.authPayload.redirectUrl,
      };
      await this.$store.dispatch("authentication/authorizeVenfor", user);
    },

    }
}
</script>

<style>

</style>